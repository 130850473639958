.ExploreContainer {
    display: flex;
    flex-direction: column;
    padding-top: 80pt;
}

.SearchForm {
    position: fixed;
    top: 16pt;
    left: 16pt;
    right: 16pt;
    display: flex;
    justify-content: center;
    border: var(--primary-border);
    border-radius: var(--primary-border-radius);
    background: var(--transparent-color);
    color: var(--primary-color);
    margin: auto;
    width: 100%;
    max-width: 956pt;
    backdrop-filter: blur(10px);
    z-index: 99;
}

.SearchInput {
    width: 100%;
    padding: 16pt;
    border: none;
    background: none;
}

.SearchInput::placeholder {
    color: var(--placeholder-color);
}

.ProjectsGrid {
    margin: 0 32pt;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24pt;
}