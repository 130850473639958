.OpeningBlock {
    width: 100%;
    border: var(--primary-border);
    border-radius: var(--primary-border-radius);
    background: var(--transparent-color);
    padding: 6pt;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
    cursor: pointer;
}

.OpeningBlock:hover {
    border-color: var(--primary-color);
}

.OpeningContainer {
    width: 100%;
    background: var(--primary-background-color);
    border-radius: calc(var(--primary-border-radius) - 6pt);
    padding: 16pt;
}

.OpeningTitle {
    margin: 0;
}

.OpeningSkillSet {
    display: flex;
    flex-wrap: wrap;
    gap: 4pt;
    margin: 12pt;
}

.OpeningInfo {
    margin: 0 8pt;
}

.OpeningContributorsContainer {
    display: flex;
    column-gap: 12pt;
    align-items: center;
    margin: 0 8pt;
}

.OpeningContributorsList {
    display: flex;
}

.OpeningContributorsText {
    font-weight: 400;
    font-size: 11pt;
}