.AuthContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Form {
    display: flex;
    flex-direction: column;
    row-gap: 12pt;
    width: 100%;
    max-width: 256pt;
    align-items: center;
}

.Form > * {
    width: 100%;
}

.FormInput {
    padding: 12pt;
    border: var(--primary-border);
    border-radius: var(--primary-border-radius);
    background: none;
    color: var(--primary-color);
}

.FormInput::placeholder {
    color: var(--placeholder-color);
}

.Hint {
    width: fit-content;
}

.Or {
    display: flex;
    position: relative;
    align-items: center;
    column-gap: 4pt;
}

.Or span {
    flex: 0;
    color: var(--placeholder-color);
}

.Or::before, .Or::after {
    content: '';
    flex: 1;
    height: 1pt;
    background-color: var(--placeholder-color);
    opacity: 0.2;
}

.Continue {
    background-color: var(--secondary-color);
    color: white;
}

.ContinueWithGoogle {
    background-color: white;
    color: black;
    display: flex;
    column-gap: 10pt;
    align-items: center;
    justify-content: center;
}

.ContinueWithGoogle::before {
    content: '';
    display: block;
    width: 16pt;
    height: 16pt;
    background-image: url(../../assets/images/google.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.SignOutBtn {
    position: absolute;
    top: 16px;
    right: 16px;
}