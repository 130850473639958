.ProfilePageContainer {
}

.HeaderContainer {
    display: flex;
    align-items: center;
    column-gap: 32pt;
    padding: 32pt;
    border-bottom: var(--primary-border);
}

.HeaderContainer > * {
    justify-items: center;
}

.HeaderProfileDescription {
    flex: 1;
}

.HeaderProfileDescription p {
    display: -webkit-box;
    -webkit-line-clamp: 16; /* number of lines to show */
            line-clamp: 16; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 11pt;
    font-weight: 400;
    line-height: 1.6em;
    filter: opacity(0.8);
}

.HeaderProfilePicture {
    align-self: center;
}

.HeaderProfileInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 12pt;
}

.Name {
    font-size: 24pt;
    font-weight: 500;
    margin: 0;
}

.ProfileStatistic {
    display: flex;
    column-gap: 12pt;
}

.SocialMediaList {
    display: flex;
    flex-wrap: wrap;
    gap: 12pt;
}

.ProfileMain {
    margin: 32pt;
}

.RolesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24pt;
}