.HeaderContainer {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 24pt;
    padding: 32pt;
    border-bottom: var(--primary-border);
}

.HeaderProjectDescription p {
    display: -webkit-box;
    -webkit-line-clamp: 16; /* number of lines to show */
            line-clamp: 16; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 11pt;
    font-weight: 400;
    line-height: 1.6em;
    filter: opacity(0.8);
}

.HeaderProjectPresentation {
    width: 50vw;
}

.BackgroundBlock {
    display: block;
    width: 100%;
    padding-bottom: 35%;
    position: relative;
    overflow: hidden;
    border-radius: var(--primary-border-radius);
}

.Background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.PictureBlock {
    width: 96pt;
    height: 96pt;
    margin-left: 48pt;
    margin-top: -48pt;
    position: relative;
    border-radius: var(--primary-border-radius);
    overflow: hidden;
}

.Picture {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.HeaderProjectContributors {

}

.ContributorsGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 8pt;
}

.ProjectNavigation {
    margin: 12pt 0;
}

.ProjectNavigationList {
    display: flex;
    column-gap: 12;
}

.ProjectNavigationItem {
    padding: 4pt 8pt;
    cursor: pointer;
}

.ProjectMainContainer {
    margin: 32pt;
}

.OpeningsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24pt;
}