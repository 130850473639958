.ProjectBlock {
    border-radius: var(--primary-border-radius);
    overflow: hidden;
    padding-bottom: 24pt;
    background: var(--transparent-color);
    border: var(--primary-border);
    cursor: pointer;
    transition: all 0.3s ease;
}

.ProjectBlock:hover {
    border-color: var(--primary-color);
}

.BackgroundBlock {
    display: block;
    width: 100%;
    padding-bottom: 35%;
    position: relative;
}

.Background {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.PictureBlock {
    width: 48pt;
    height: 48pt;
    margin-left: 24pt;
    margin-top: -24pt;
    position: relative;
    border-radius: var(--primary-border-radius);
    overflow: hidden;
}

.Picture {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProjectName {
    margin: 12pt 24pt;
    font-size: 12pt;
}

.CreateProjectBlock {
    border-radius: var(--primary-border-radius);
    overflow: hidden;
    background: none;
    border: var(--primary-border);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--placeholder-color);
    transition: all 0.3s ease;
    cursor: pointer;
}

.CreateProjectText::before {
    content: '+';
}

.CreateProjectText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CreateProjectBlock:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.SkillList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 24pt;
    gap: 4pt;

}

.SkillPreviewItem {
    padding: 4pt 8pt;
    border-radius: 4pt;
    text-wrap: nowrap;
    background: var(--transparent-color);
    font-weight: 400;
    font-size: 10pt;
}