.Calendar {
    width: 300px;
    border: var(--primary-border);
    border-radius: var(--primary-border-radius);
    overflow: hidden;
    font-family: Arial, sans-serif;
}

.CalendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-background);
    padding: 10px;
}
    
  
.CalendarGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    padding: 10px;
}
  
.CalendarDayHeader {
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
}
  
.CalendarDay {
    text-align: center;
    padding: 10px 0;
    border-radius: 4px;
    cursor: pointer;
    background: none;
}
  
.CalendarDay:hover {
    background: var(--transparent-color);
  }
  
.Empty {
    background: none;
    pointer-events: none;
}
  