.MainText {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 6pt;
}

.ProjectLogo {
    display: inline-block;
    width: 32pt;
    height: 32pt;
    background-image: url(../../assets/images/favicon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}