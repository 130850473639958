.ProfilePicturePreview {
    width: 48pt;
    height: 48pt;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.ProfilePicturePreview img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}