@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,200&display=swap');

html[data-theme="light"] {
    --transparent-coefficient: 5%;
    --transparent-color: rgba(0,0,0, var(--transparent-coefficient));
    --primary-border: solid 1px var(--transparent-color);
    --primary-border-radius: 12pt;
    --placeholder-color: rgba(0,0,0,0.5);
    --primary-color: #1d1d1d;
    --primary-background-color: #ffffff;
    --secondary-color: #524395;
}

html[data-theme="dark"] {
    --transparent-color: rgba(255,255,255,0.07);
    --primary-border: solid 1px var(--transparent-color);
    --primary-border-radius: 12pt;
    --placeholder-color: rgba(255,255,255,0.5);
    --primary-color: #ffffff;
    --primary-background-color: #1f1f1f;
    --secondary-color: #524395;
    scrollbar-color: var(--placeholder-color) transparent;
}

* {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 500;
}

body {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--primary-background-color);
    color: var(--primary-color);
}


#root {
    position: relative;
    width: 100%;
    min-height: 100%;
}

.data-theme {
    position: absolute;
    bottom: 12pt;
    right: 12pt;
    background: none;
    color: var(--primary-color);
    border:  none;
    cursor: pointer;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.placeholder-color {
    color: var(--placeholder-color);
}